import AIRPAY from "gateways/airpay";
import CCAVENUE from "gateways/ccavenue";
import PAYU from "gateways/payU";
import RAZORPAY from "gateways/razorPay";
import PHONEPE from "gateways/phonepe";
import { ComponentType } from "react";
import { useParams } from "react-router";

type GatewayMapType = {
  [key in string]: { component: ComponentType };
};

type GatewayRendererParams = { gateway: string };

const GatewayMap: GatewayMapType = {
  CCAVENUE: {
    component: CCAVENUE,
  },
  PAYU: {
    component: PAYU,
  },
  AIRPAY: {
    component: AIRPAY,
  },
  PHONEPE: {
    component: PHONEPE,
  },
  RAZORPAY: {
    component: RAZORPAY,
  },
  // stripe: {  // Not integrated Right Now
  //   component: Stripe,
  // },
};

const GatewayRenderer = () => {
  const { gateway } = useParams<GatewayRendererParams>();

  const Gateway = GatewayMap[gateway!].component;

  return <Gateway />;
};

export default GatewayRenderer;
