import { Box, Skeleton } from "@mui/material";
import { memo, useState } from "react";
import { SxProps } from "types/common";

type LazyImageProps = {
  src: string;
  alt: string;
  onLoading?: (isLoaded: boolean) => void;
  customStyles?: SxProps;
  fallback?: JSX.Element;
};

const LazyImage = ({
  alt,
  src,
  onLoading,
  customStyles,
  fallback,
}: LazyImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const customLoadHandler = (): void => {
    setIsLoaded(true);
    onLoading && onLoading(true);
  };

  return (
    <>
      {!isLoaded &&
        (fallback || <Skeleton style={{ width: "100%", height: "100%" }} />)}
      <Box
        component="img"
        style={isLoaded ? {} : { display: "none" }}
        alt={alt}
        src={src}
        onLoad={customLoadHandler}
        sx={customStyles}
      />
    </>
  );
};

export default memo(LazyImage);
