import { UserInfo } from "@botshot_echosystems/botpay-helpers/initPaymentModule";
import { useMutation } from "@tanstack/react-query";
import fetcher from "dataProvider";
import GatewayEngine from "gatewayEngine";
import GatewayRenderer from "gatewayEngine/GatewayRenderer";
import useQueryParams from "hooks/useQueryParams";
import { useEffect } from "react";
import { Route, Routes } from "react-router";
import { setGatewayDetails, setUIConfig, setUserInfo } from "redux/actions";
import { useAppDispatch } from "redux/store";

type Payload = {
  userInfo: UserInfo;
  paymentApiKey: string;
};

const Home = () => {
  const dispatch = useAppDispatch();
  const query = useQueryParams();

  const { data, mutate } = useMutation(
    [query],
    ({
      userInfo: { appName, entityId, reservationId },
      paymentApiKey,
    }: Payload) =>
      fetcher.post(
        `/api/v1/payment/register`,
        {
          appName,
          entityId,
          reservationId,
        },
        {},
        { "entity-id": entityId, key: paymentApiKey }
      )
  );

  useEffect(() => {
    if (query?.get("token")) {
      const tokenData = JSON.parse(window.atob(query?.get("token")!));
      dispatch(setUserInfo(tokenData?.userInfo));
      dispatch(setGatewayDetails(tokenData?.gatewayDetails));
      dispatch(setUIConfig(tokenData?.uiConfig));
      mutate({
        userInfo: tokenData?.userInfo,
        paymentApiKey: tokenData?.paymentApiKey,
      });
    }
  }, [dispatch, mutate, query]);

  if (!data) return <>Loading...</>;

  return (
    <Routes>
      <Route index element={<GatewayEngine />} />
      <Route path=":gateway" element={<GatewayRenderer />} />
    </Routes>
  );
};

export default Home;
