import { SxProps } from "types/common";

const styles: SxProps = {
  cardWrapper: {
    width: "250px",
    height: "176px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: (theme: any) => `1px solid rgba(112, 112, 112, 0.5)`,
    borderRadius: "12px",
    cursor: "pointer",
    "&:hover": {
      border: (theme: any) => `2px solid #2186d4`,
    },
  },
  img: {
    width: "80%",
    height: "26%",
  },
};

export default styles;
