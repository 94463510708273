import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import ErrorComp from "components/Error/Error";
import PaymentProgress from "components/PaymentProgress";
import fetcher from "dataProvider";
import { useEffect } from "react";
import { useAppSelector } from "redux/store";

const CCAVENUE = () => {
  const { gatewayDetails } = useAppSelector((state) => state.gatewayInfo);
  const { entityId } = useAppSelector((state) => state.userInfo);

  const payload = gatewayDetails.find(
    (gateway) => gateway.gatewayType === "CCAVENUE"
  )?.payload;

  const { mutate, error, isError } = useMutation((mutateData: any) =>
    fetcher.put(`/api/v1/payment/webhook`, mutateData, undefined, {
      "entity-id": entityId,
    })
  );

  useEffect(() => {
    mutate(
      {
        ...payload,
        gatewayType: "CCAVENUE",
      },
      {
        onSuccess: (res) =>
          (window.parent.location.href = res?.data?.billingUrl),
      }
    );
  }, [mutate, payload]);

  if (isError) {
    return <ErrorComp message={(error as AxiosError<{ message: string }>)?.response?.data?.message} />
  }
  return <PaymentProgress />;
};

export default CCAVENUE;
