import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import gatewayInfo from "./slice/gatewayInfo";
import uiConfig from "./slice/uiConfig";
import userInfo from "./slice/userInfo";

const rootReducer = combineReducers({
  routing: routerReducer,
  gatewayInfo,
  uiConfig,
  userInfo,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
