import { UserInfo } from "@botshot_echosystems/botpay-helpers/initPaymentModule";
import { createSlice } from "@reduxjs/toolkit";

const initialState: UserInfo = {
  appName: "web",
  entityId: "",
  reservationId: "",
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (_, { payload }) => payload,
    resetUser: () => initialState,
  },
});

export const { setUserInfo, resetUser } = userInfoSlice.actions;

export default userInfoSlice.reducer;
