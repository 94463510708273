import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const ACCESS_TOKEN = "x-access-token";

const userSessionActive = (value: { accessToken: string }) =>
  localStorage.setItem(ACCESS_TOKEN, value.accessToken);

const handleRequest = (req: AxiosRequestConfig) => {
  if (req.url !== "/api/v1/payment/register")
    req.headers![ACCESS_TOKEN] = `${localStorage.getItem(ACCESS_TOKEN)}`;
  return req;
};

const handleResponse = (res: AxiosResponse<any>) => {
  if (res?.config?.url === "/api/v1/payment/register") {
    /**
     * set token if user is verified
     */
    userSessionActive({
      accessToken: res.data["token"],
    });
  }
  return res;
};

const handleError = async (err: AxiosError<any>) => Promise.reject(err);

instance.interceptors.request.use(handleRequest);

instance.interceptors.response.use(handleResponse, (error) =>
  handleError(error)
);

export default instance;
