import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import ErrorComp from "components/Error/Error";
import PaymentProgress from "components/PaymentProgress";
import fetcher from "dataProvider";
import { useCallback, useEffect } from "react";
import { useAppSelector } from "redux/store";

const PAYU = () => {
  const { gatewayDetails } = useAppSelector((state) => state.gatewayInfo);
  const { entityId } = useAppSelector((state) => state.userInfo);

  const payload = gatewayDetails.find(
    (gateway) => gateway.gatewayType === "PAYU"
  )?.payload;

  const { mutate, error, isError } = useMutation((mutateData: any) =>
    fetcher.put(`/api/v1/payment/webhook`, mutateData, undefined, {
      "entity-id": entityId,
    })
  );

  const createHiddenFields = (name: string, value: string) => {
    let EID = document.createElement("input");
    EID.setAttribute("type", "hidden");
    EID.setAttribute("name", name);
    EID.setAttribute("value", value);
    return EID;
  };

  const createForm = useCallback((response: any) => {
    let form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("target", "_parent");
    form.setAttribute("action", response.billingUrl);
    form.appendChild(createHiddenFields("key", response.transaction.key));
    form.appendChild(
      createHiddenFields("txnid", response.transaction.transactionId)
    );
    form.appendChild(createHiddenFields("amount", response.transaction.amount));
    form.appendChild(
      createHiddenFields("productinfo", response.transaction.productinfo)
    );
    form.appendChild(
      createHiddenFields("firstname", response.transaction.firstname)
    );
    form.appendChild(createHiddenFields("email", response.transaction.email));
    form.appendChild(createHiddenFields("hash", response.transaction.hash));
    form.appendChild(createHiddenFields("surl", response.transaction.surl));
    form.appendChild(createHiddenFields("furl", response.transaction.furl));
    form.appendChild(createHiddenFields("curl", response.transaction.curl));
    form.appendChild(createHiddenFields("SALT", response.transaction.SALT));
    document.getElementsByClassName("payment-form")[0].appendChild(form);
    form.submit();
    form.remove();
  }, []);

  useEffect(() => {
    mutate(
      {
        ...payload,
        gatewayType: "PAYU",
      },
      {
        onSuccess: (res) => createForm(res?.data),
      }
    );
  }, [createForm, mutate, payload]);

  if (isError) {
    return <ErrorComp message={(error as AxiosError<{ message: string }>)?.response?.data?.message} />
  }
  return (
    <PaymentProgress>
      <div className="payment-form" style={{ display: "hidden" }} />
    </PaymentProgress>
  );
};

export default PAYU;
