import { SxProps } from "types/common";

const styles: SxProps = {
  gatewaysWrapper: {
    width: "100%",
    padding: "46px 33px 0",
  },
  paymentHeading: {
    fontFamily: "Montserrat",
    fontSize: "25px",
    fontWeight: 600,
    color: "#121212",
  },
  cardsWrapper: {
    marginTop: "37px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "40px",
  },
  proceedPayBtn: {
    marginTop: "200px",
  },
};

export default styles;
