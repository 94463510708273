import { Box } from "@mui/material";
import LazyImage from "components/LazyImage";
import { useState } from "react";
import { SxProps } from "types/common";
import styles from "./styles";

type CardProps = {
  logo: string;
  name: string;
  onCardSelect: () => void;
  disabled: boolean;
};

const Card = ({ name, logo, onCardSelect, disabled }: CardProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Box
      onClick={() => isLoaded && !disabled && onCardSelect()}
      sx={styles.cardWrapper}
    >
      <LazyImage
        src={logo}
        alt={name}
        onLoading={setIsLoaded}
        customStyles={
          disabled
            ? ({ opacity: "0.5", ...styles.img } as SxProps)
            : (styles.img as SxProps)
        }
      />
    </Box>
  );
};
export default Card;
