import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import ErrorComp from "components/Error/Error";
import PaymentProgress from "components/PaymentProgress";
import fetcher from "dataProvider";
import { useCallback, useEffect } from "react";
import { useAppSelector } from "redux/store";

const airPayUrl = process.env.REACT_APP_PUBLIC_AIRPAY_URL as string;

const AIRPAY = () => {
  const { gatewayDetails } = useAppSelector((state) => state.gatewayInfo);
  const { entityId } = useAppSelector((state) => state.userInfo);

  const payload = gatewayDetails.find(
    (gateway) => gateway.gatewayType === "AIRPAY"
  )?.payload;

  const { mutate, error, isError } = useMutation((mutateData: any) =>
    fetcher.put(`/api/v1/payment/webhook`, mutateData, undefined, {
      "entity-id": entityId,
    })
  );

  const createHiddenFields = (name: string, value: string) => {
    let EID = document.createElement("input");
    EID.setAttribute("type", "hidden");
    EID.setAttribute("name", name);
    EID.setAttribute("value", value);
    return EID;
  };

  const createForm = useCallback((response: any) => {
    let form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("target", "_parent");
    form.setAttribute("action", airPayUrl);
    // form.setAttribute("action", response.billingUrl); // TODO billingUrl from api

    form.appendChild(
      createHiddenFields("buyerEmail", response.transaction.email)
    );
    form.appendChild(
      createHiddenFields("buyerPhone", response.transaction.phoneNumber)
    );
    form.appendChild(
      createHiddenFields("buyerFirstName", response.transaction.firstname) //TODO firstName key change
    );
    form.appendChild(
      createHiddenFields("buyerLastName", response.transaction.lastName)
    );
    form.appendChild(
      createHiddenFields("customvar", response.transaction.customvar)
    );
    form.appendChild(
      createHiddenFields("orderid", response.transaction.orderId)
    );
    form.appendChild(createHiddenFields("amount", response.transaction.amount));
    form.appendChild(createHiddenFields("UID", response.transaction.uid));
    form.appendChild(
      createHiddenFields("privatekey", response.transaction.privateKey)
    );
    form.appendChild(
      createHiddenFields("mercid", response.transaction.merchantId)
    );
    form.appendChild(
      createHiddenFields("checksum", response.transaction.checksum)
    );
    form.appendChild(
      createHiddenFields("currency", response.transaction.currency)
    );
    form.appendChild(
      createHiddenFields("isocurrency", response.transaction.isocurrency)
    );

    document.getElementsByClassName("payment-form")[0].appendChild(form);
    form.submit();
    form.remove();
  }, []);

  useEffect(() => {
    mutate(
      {
        ...payload,
        gatewayType: "AIRPAY",
      },
      {
        onSuccess: (res) => createForm(res?.data),
      }
    );
  }, [createForm, mutate, payload]);

  if (isError) {
    return <ErrorComp message={(error as AxiosError<{ message: string }>)?.response?.data?.message} />
  }
  return (
    <PaymentProgress>
      <div className="payment-form" style={{ display: "hidden" }} />
    </PaymentProgress>
  );
};

export default AIRPAY;
