import { SxProps } from "types/common";

const styles: SxProps = {
  cardWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "4rem",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    width: "100px",

    height: "26%",
  },
  button: {
    width: "135px",
    fontSize: "14px",
    height: "48px",
    borderRadius: "6px",
    boxShadow: "none",
    backgroundColor: "#5f38f9",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4423c6",
      boxShadow: "none",
    },
  },
};

export default styles;
