import { GatewayDetail } from "@botshot_echosystems/botpay-helpers/initPaymentModule";
import { Box } from "@mui/material";
import Card from "components/Card";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/store";
import styles from "./styles";

const GatewayEngine = () => {
  const navigate = useNavigate();

  const { heading, variant } = useAppSelector((state) => state.uiConfig);
  const { gatewayDetails } = useAppSelector((state) => state.gatewayInfo);

  useEffect(() => {
    if (variant === "mini") navigate(`${gatewayDetails[0].gatewayType}`);
  }, [gatewayDetails, navigate, variant]);

  return (
    <Box sx={styles.gatewaysWrapper}>
      <Box sx={styles.paymentHeading}>{heading}</Box>
      <Box sx={styles.cardsWrapper}>
        {gatewayDetails?.map((gateway: GatewayDetail, idx: number) => (
          <Card
            key={gateway.gatewayType}
            logo={gateway.imgSrc}
            name={gateway.gatewayType}
            disabled={false}
            onCardSelect={() => navigate(`${gateway.gatewayType}`)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default GatewayEngine;
