import { Box, Button, Typography } from '@mui/material'
import React, { FC } from 'react'
import styles from './styles'
import { useNavigate } from 'react-router-dom'

interface Props {
    message?: string
}
const ErrorComp: FC<Props> = ({ message }) => {
    const nav = useNavigate()
    return (
        <Box sx={styles.cardWrapper}>
            <Box component={'img'} sx={styles.img} src='/warning.svg' ></Box>
            <Typography variant='h5'>{message || "Something went wrong. Please try again"}</Typography>
            <Button sx={styles.button} variant='contained' onClick={() => nav(-1)}>Go Back</Button>
        </Box>
    )
}

export default ErrorComp