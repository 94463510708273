import { Box, CircularProgress } from "@mui/material";
import styles from "gatewayEngine/styles";
import { ReactNode } from "react";

type PaymentProgressProps = {
  label?: string;
  children?: ReactNode;
};

const PaymentProgress = ({ label, children }: PaymentProgressProps) => {
  return (
    <Box sx={styles.progressWrapper}>
      {children}
      {label ||
        "Payment is being processed, kindly avoid refreshing or navigating away."}
      <CircularProgress />
    </Box>
  );
};

export default PaymentProgress;
