import { UIConfig } from "@botshot_echosystems/botpay-helpers/initPaymentModule";
import { createSlice } from "@reduxjs/toolkit";

const initialState: UIConfig = {
  heading: "",
  variant: "standard",
};

const uiConfigSlice = createSlice({
  name: "payStatus",
  initialState,
  reducers: {
    setUIConfig: (_, { payload }) => payload,
    resetUIConfig: () => initialState,
  },
});

export const { setUIConfig, resetUIConfig } = uiConfigSlice.actions;

export default uiConfigSlice.reducer;
